import { format } from 'date-fns'
import React from 'react'
import { isBrowser, partInViewport } from '../lib/helpers'
import PortableText from './portableText'
import GallerySlider from './gallery-slider'
import classNames from 'classnames'
import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group'
import { Link } from 'gatsby'
import InfoI from '../images/icons/info-i.svg'

class Collaboration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBodySliderRendereded: false,
      isHeaderSliderVisible: true,
      isOverlaySliderOpen: false,
      isOverlaySliderVisible: true,
      slideIndex: 0,
    }
    this.modalRef = React.createRef()
    this.pageContainerRef = React.createRef()
    this.toggleOverlaySlider = this.toggleOverlaySlider.bind(this)
  }

  onScroll = () => {
    const el = document.getElementById('gallery-slider-header')
    if (el) {
      this.setState({
        isHeaderSliderVisible: partInViewport(el, 0, 136),
      })
    }
  }

  toggleBodySlider = isVisible => {
    this.setState({
      isHeaderSliderVisible: isVisible,
    })
  }

  setSlideIndex = i => {
    this.setState({
      slideIndex: i,
    })
  }

  toggleOverlaySlider = () => {
    const { isOverlaySliderOpen, isOverlaySliderVisible } = this.state
    // toggle slider open
    this.setState({
      isOverlaySliderOpen: !isOverlaySliderOpen,
    })
    if (isOverlaySliderVisible) {
      document.body.classList.add('overlay-slider-transition-delay')
      setTimeout(() => {
        document.body.classList.remove('overlay-slider-transition-delay')
      }, 400)
    }
  }

  renderButtonLinks(link) {
    const _link = link.article ? link.article : link
    if (_link.artistReference) {
      const slug = _link.artistReference.slug && _link.artistReference.slug.current
      if (slug)
        return (
          <Link className="btn" to={`/artists/${slug}`}>
            {_link.title ? _link.title : 'Bio'}
          </Link>
        )
    } else {
      return (
        <a
          className="btn"
          href={_link.download ? _link.download.asset.url : _link.externalLink}
          target="_blank"
          rel="noopener noreferrer"
          key={link._key}
        >
          {_link.title}
        </a>
      )
    }
  }

  render() {
    const {
      artist,
      venue,
      location,
      startDate,
      endDate,
      title,
      tags,
      artistListTitle,
      artistList,
      showArtistList,
      relatedCollaborationsTitle,
      relatedCollaborations,
      _rawFormattedTitle,
      _rawPressLinks,
      _rawBioLinks,
      _rawGallerySlides,
      _rawBody,
      _rawArtistGroup,
    } = this.props
    const startYear = format(startDate, 'YYYY')
    const classes = classNames({
      'is-body-slider-rendered': this.state.isBodySliderRendereded,
      'is-body-slider-visible': !this.state.isHeaderSliderVisible,
      'has-artist-group': _rawArtistGroup,
    })
    const hasArticles = _rawPressLinks && _rawPressLinks.length

    return (
      <div className="page-container" ref={this.pageContainerRef}>
        <div className={`collaboration ${classes}`}>
          <div className="collaboration__header">
            <h1 className="collaboration__title">
              {title && artist && (
                <>
                  <span className="collaboration-title__name">
                    <em>
                      {_rawFormattedTitle ? (
                        <PortableText blocks={_rawFormattedTitle} />
                      ) : (
                        <>{title}</>
                      )}
                    </em>
                    {artist}
                    <br />
                  </span>
                </>
              )}
              {venue && (
                <>
                  <span>{venue}</span>
                  <br />
                </>
              )}
              {location && (
                <>
                  <span>{location}</span>
                  <br />
                </>
              )}
              {startDate && endDate && (
                <>
                  <span>
                    <time dateTime={startDate}>{format(startDate, 'DD.MM')}</time>
                    .–
                    <time dateTime={endDate}>{format(endDate, 'DD.MM.YYYY')}</time>
                  </span>
                </>
              )}
            </h1>
            <div className="collaboration__tags">
              {tags &&
                tags.map(tag => (
                  <div className="btn content-tag content-tag--info" key={tag._id}>
                    {tag.title}
                  </div>
                ))}
            </div>
          </div>
          {/* header slider */}
          {_rawGallerySlides && (
            <GallerySlider
              type="header"
              title={title}
              artist={artist}
              location={location}
              startYear={startYear}
              slides={_rawGallerySlides}
              slideIndex={this.state.slideIndex}
              setSlideIndex={this.setSlideIndex}
              toggleOverlaySlider={this.toggleOverlaySlider}
            />
          )}
          {/* bio and press button links */}
          <div className="collaboration__links">
            {_rawBioLinks && _rawBioLinks.map(link => this.renderButtonLinks(link))}
            {_rawPressLinks && _rawPressLinks.length ? (
              <Link className="btn" to="press">
                Press
              </Link>
            ) : (
              <></>
            )}
          </div>
          {/* body slider */}
          {_rawGallerySlides && (
            <GallerySlider
              type="body"
              title={title}
              artist={artist}
              location={location}
              startYear={startYear}
              slides={_rawGallerySlides}
              bioLinks={_rawBioLinks}
              hasArticles={hasArticles}
              slideIndex={this.state.slideIndex}
              setSlideIndex={this.setSlideIndex}
              toggleOverlaySlider={this.toggleOverlaySlider}
            />
          )}
          {/* overlay slider */}
          {_rawGallerySlides && (
            <CSSTransition in={this.state.isOverlaySliderOpen} timeout={400}>
              <Modal
                className="gallery-slider__overlay-container"
                closeTimeoutMS={400}
                isOpen={this.state.isOverlaySliderOpen}
                modalRef={this.modalRef}
              >
                <GallerySlider
                  type="overlay"
                  title={title}
                  artist={artist}
                  location={location}
                  startYear={startYear}
                  slides={_rawGallerySlides}
                  slideIndex={this.state.slideIndex}
                  setSlideIndex={this.setSlideIndex}
                  toggleOverlaySlider={this.toggleOverlaySlider}
                />
              </Modal>
            </CSSTransition>
          )}
          {/* body */}
          <div className="collaboration__body">
            <div className="collaboration__body-inner">
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
          </div>
          {/* artist list */}
          {artistList && showArtistList && (
            <>
              <div className="collaboration__artist-list">
                <div className="btn btn--info">
                  {artistListTitle ? artistListTitle : 'Participant Artists'}
                </div>
                <ul>
                  {artistList.map((artist, i) => {
                    if (artist.slug)
                      return (
                        <li key={i}>
                          <Link to={`/artists/${artist.slug.current}`}>
                            <div className="btn icon-btn">
                              <InfoI />
                            </div>
                            <div>{artist.title}</div>
                          </Link>
                        </li>
                      )
                  })}
                </ul>
              </div>
            </>
          )}
          {/* related collaborations */}
          {relatedCollaborations && relatedCollaborations.length ? (
            <>
              <div className="collaboration__related-list">
                <div className="btn btn--info">
                  {relatedCollaborationsTitle
                    ? relatedCollaborationsTitle
                    : 'Featured Collaborations'}
                </div>
                {relatedCollaborations.map((node, i) => {
                  const { title, location, startDate, endDate, slug, _rawFormattedTitle } = node
                  return (
                    <li key={node.id}>
                      <Link to={`/collaborations/${slug.current}`}>
                        {_rawFormattedTitle ? (
                          <em>
                            <PortableText blocks={_rawFormattedTitle} />
                          </em>
                        ) : (
                          <>{title && <em>{title}</em>}</>
                        )}
                        {location && <div>{location}</div>}
                        {startDate && endDate && (
                          <>
                            <span>
                              <time dateTime={startDate}>{format(startDate, 'DD.MM')}</time>–
                              <time dateTime={endDate}>{format(endDate, 'DD.MM.YYYY')}</time>
                            </span>
                          </>
                        )}
                      </Link>
                    </li>
                  )
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    document.addEventListener('scroll', this.onScroll)
    // this.onScroll()
    this.forceUpdate()
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }
}

export default Collaboration
