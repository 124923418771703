import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Collaboration from '../components/collaboration'
import SEO from '../components/seo'
import ContentContainer from '../containers/content'
import Conversation from '../components/conversation'
import { Helmet } from 'react-helmet'

// export default function() {
//   return <h1>jihihi</h1>
// }

export const query = graphql`
  query ConversationTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    conversation: sanityConversation(id: { eq: $id }) {
      id
      title
      artist
      audio {
        asset {
          url
        }
      }
      mainImage {
        ...SanityImage
        asset {
          fluid(maxWidth: 2400) {
            ...GatsbySanityImageFluid
          }
        }
        title
        credits
        alt
      }
      previewImageSize {
        widthDesktop
      }
      tags {
        _id
        title
      }
      pressLinks {
        title
        externalLink
        download {
          asset {
            url
          }
        }
      }
      artistListTitle
      artistList {
        title
        slug {
          current
        }
      }
      showArtistList
      collaboratorListTitle
      collaboratorList {
        title
        slug {
          current
        }
      }
      showCollaboratorList
      slug {
        current
      }
      metaDescription
      openGraph {
        image {
          ...SanityImage
        }
      }
      _rawFormattedTitle
      _rawPressLinks(resolveReferences: { maxDepth: 5 })
      _rawBioLinks(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      _rawArtistGroup(resolveReferences: { maxDepth: 5 })
    }
  }
`

const ConversationTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const conversation = data && data.conversation
  const conversationOgImage = conversation.openGraph && conversation.openGraph.image
  const SEOtitle = `${conversation.title}, ${conversation.artist}`

  return (
    <>
      <Helmet>
        <html className="is-bg-black" />
      </Helmet>
      <ContentContainer section="conversation" singleView bgBlack>
        
        {errors && <SEO title="GraphQL Error" />}
        {conversation && (
          <SEO
            title={SEOtitle || site.title}
            description={conversation.metaDescription || site.description}
            keywords={site.keywords}
            image={conversationOgImage || siteOgImage}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {conversation && <Conversation {...conversation} />}
      </ContentContainer>
    </>
  )
}

export default ConversationTemplate
